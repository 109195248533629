import { send } from './email';
import { signIn } from './signIn';
import { startScenario, branchSelection, inputResult } from './scenario';

export const useServices = () => {
  const emailService = { send };
  const signInService = { signIn };
  const scenarioService = { startScenario, branchSelection, inputResult };

  return {
    emailService,
    signInService,
    scenarioService,
  };
};
