import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { trackUserId, trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { createChannel } from '../../../../firebase';
import { signInByCustomToken } from '../../../../firebase/auth';
import { useServices } from '../../../../service';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';

export const useStartScenario = () => {
  const app = useRecoilValue(appState);
  const setIdentity = useSetRecoilState(chatbotIdentityState);
  const { signInService, scenarioService } = useServices();

  const { addMessage } = useMessageUtil();
  const startScenario = useCallback(async () => {
    try {
      const authResponse = await signInService.signIn({ apiKey: app.apiKey });
      await signInByCustomToken(authResponse.firebaseCustomToken);
      const response = await scenarioService.startScenario(
        {
          project_id: String(authResponse.identity.projectId),
          scenario_id: app.scenarioId,
        },
        authResponse.identity.firebaseToken,
        app.apiKey,
        app.siteOrigin,
      );
      const channelId = await createChannel(
        String(authResponse.identity.projectId),
        authResponse.identity.firebaseUuid,
        app.scenarioId,
        app.chatKey,
      );
      trackUserId(authResponse.identity.firebaseUuid);

      trackScreenViewEvent('start_chat');
      setIdentity({ identity: authResponse.identity, channelId });
      await addMessage(response.scenario_data, authResponse.identity.projectId, channelId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [
    addMessage,
    app.apiKey,
    app.chatKey,
    app.scenarioId,
    app.siteOrigin,
    scenarioService,
    setIdentity,
    signInService,
  ]);
  return { startScenario };
};
