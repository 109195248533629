import { IChatContext } from '../ChatProvider.types';
import { useBranchSelection } from './useBranchSelection';
import { useHideChat } from './useHideChat';
import { useInputResult } from './useInputResult';
import { useRestartScenario } from './useRestartScenario';
import { useStartScenario } from './useStartScenario';

export const useChat = () => {
  const { startScenario } = useStartScenario();
  const { branchSelection } = useBranchSelection();
  const { inputResult } = useInputResult();
  const { hideChat } = useHideChat();
  const { reset } = useRestartScenario();

  const value: IChatContext = {
    startChat: startScenario,
    hideChat,
    branchSelection,
    inputResult,
    reset,
  };

  return { value };
};
