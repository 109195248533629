import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useServices } from '../../../../service';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';

export const useBranchSelection = () => {
  const app = useRecoilValue(appState);
  const { identity } = useRecoilValue(chatbotIdentityState);
  const { scenarioService } = useServices();

  const { addMessage, addUserMessage } = useMessageUtil();
  const branchSelection = useCallback(
    async (params: { choice: number; node_id: string; text: string }) => {
      const { text, choice } = params;
      const nodeId = params.node_id;

      const response = await scenarioService.branchSelection(
        {
          node_id: nodeId,
          selection: Number(choice),
          project_id: String(identity.projectId),
          user_id: identity.firebaseUuid,
          scenario_id: app.scenarioId,
        },
        identity.firebaseToken,
        app.apiKey,
        app.siteOrigin,
      );

      await addUserMessage(text);
      await addMessage(response.scenario_data);
    },
    [
      addMessage,
      addUserMessage,
      app.apiKey,
      app.scenarioId,
      app.siteOrigin,
      identity.firebaseToken,
      identity.firebaseUuid,
      identity.projectId,
      scenarioService,
    ],
  );

  return { branchSelection };
};
