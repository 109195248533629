/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { addMessageLog } from '../../../firebase';
import { useServices } from '../../../service';
import { EInstanceType, TMessage } from '../../../service/scenario/models/scenario';
import { appState, chatbotIdentityState, messageState, messageVariablesState } from '../../../states';

const MESSAGE_SHOW_INTERVAL = 1000;

export const useMessageUtil = () => {
  const app = useRecoilValue(appState);
  const setMessages = useSetRecoilState(messageState);
  const [variables, setVariables] = useRecoilState(messageVariablesState);
  const chatbotIdentity = useRecoilValue(chatbotIdentityState);
  const { identity } = chatbotIdentity;
  const { emailService } = useServices();

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

  const replaceMessageVariable = useCallback(
    (message: string) => {
      if (!message) return message;
      if (variables.variables.size === 0) return message;
      let replacedMessage = message;

      variables.variables.forEach((value, key) => {
        replacedMessage = replacedMessage.replace(key, value);
      });
      return replacedMessage;
    },
    [variables],
  );

  const messageAction = useCallback(
    async (message: TMessage, projectId: number, channelId: string) => {
      switch (message.instance_type) {
        case EInstanceType.avatar_utterance: {
          const replacedMessage = replaceMessageVariable(message.params.display_text);
          addMessageLog(String(projectId), channelId, replacedMessage, app.chatKey)
            .then()
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e);
            });
          setMessages((prev) =>
            prev.concat({
              ...message,
              user_created: 'avatar',
              created_at: new Date().toISOString(),
              params: { ...message.params, display_text: replacedMessage },
            }),
          );
          trackScreenViewEvent('show_message', {
            event_label: channelId,
            value: projectId,
            description: replacedMessage,
          });
          await sleep(MESSAGE_SHOW_INTERVAL);
          break;
        }
        case EInstanceType.text_message: {
          const replacedMessage = replaceMessageVariable(message.params.display_text);
          addMessageLog(String(projectId), channelId, replacedMessage, app.chatKey)
            .then()
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e);
            });
          setMessages((prev) =>
            prev.concat({
              ...message,
              user_created: 'avatar',
              created_at: new Date().toISOString(),
              params: { ...message.params, display_text: replacedMessage },
            }),
          );
          trackScreenViewEvent('show_message', {
            event_label: channelId,
            value: projectId,
            description: replacedMessage,
          });
          await sleep(MESSAGE_SHOW_INTERVAL);
          break;
        }
        case EInstanceType.end_of_scenario: {
          setMessages((prev) => prev.concat(message));
          emailService
            .send(
              {
                apiKey: app.apiKey,
                projectId,
                channelId,
                chatbotId: app.chatKey,
              },
              identity.firebaseToken,
            )
            .then()
            // eslint-disable-next-line no-console
            .catch((e) => console.error(e));
          trackScreenViewEvent('sent_email', {
            event_label: channelId,
            value: projectId,
          });
          break;
        }
        case EInstanceType.user_input: {
          setMessages((prev) => prev.concat(message));
          setVariables({ ...variables, currentSaveNameKey: message.params.save_name });
          break;
        }
        default:
          setMessages((prev) => prev.concat(message));
          break;
      }
    },
    [
      app.apiKey,
      app.chatKey,
      emailService,
      identity.firebaseToken,
      replaceMessageVariable,
      setMessages,
      setVariables,
      variables,
    ],
  );

  const addMessage = useCallback(
    async (messages: TMessage[], projectId = identity.projectId, channelId: string = chatbotIdentity.channelId) => {
      // eslint-disable-next-line no-restricted-syntax
      for await (const message of messages) {
        await messageAction(message, projectId, channelId);
      }
    },
    [chatbotIdentity.channelId, identity.projectId, messageAction],
  );

  const addUserMessage = useCallback(
    async (message: string) => {
      const userMessage: TMessage[] = [
        {
          user_created: identity.firebaseUuid,
          node_id: '',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          instance_type: EInstanceType.text_message,
          params: {
            display_text: message,
          },
        },
      ];
      const projectId = String(chatbotIdentity.identity.projectId);
      addMessageLog(projectId, chatbotIdentity.channelId, message, app.chatKey)
        .then()
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      setMessages((prev) => prev.concat(userMessage));
      await sleep(MESSAGE_SHOW_INTERVAL);
    },
    [app.chatKey, chatbotIdentity, identity.firebaseUuid, setMessages],
  );

  return {
    addMessage,
    addUserMessage,
  };
};
