import { TMessage } from './models/scenario';

type TBranchSelectionParams = {
  project_id: string;
  scenario_id: string;
  user_id: string;
  node_id: string;
  selection: number;
};

export type TBranchSelectionResponseBody = {
  scenario_data: TMessage[];
};

// TODO: エラー処理を書く
export async function branchSelection(
  params: TBranchSelectionParams,
  token: string,
  apiKey: string,
  origin: string,
): Promise<TBranchSelectionResponseBody> {
  const response = await fetch(
    `${
      process.env.NEXT_PUBLIC_ADD_BACKEND || 'http://localhost:8080'
    }/customer/anonymous/chatbot/add_talk/branch_selection`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-add-api-key': apiKey,
        'X-embedded-origin': origin,
      },
      body: JSON.stringify(params),
    },
  );

  if (!response.ok) {
    throw new Error('シナリオの取得に失敗しました。');
  }
  return (await response.json()) as TBranchSelectionResponseBody;
}
