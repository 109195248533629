export enum EInstanceType {
  sequence_call = 'sequence_call',
  avatar_utterance = 'avatar_utterance',
  avatar_motion = 'avatar_motion',
  avatar_motion_video = 'avatar_motion_video',
  open_image = 'open_image',
  close_image = 'close_image',
  open_video = 'open_video',
  close_video = 'close_video',
  save_param = 'save_param',
  random_instance_group = 'random_instance_group',
  user_choices = 'user_choices',
  param_branch = 'param_branch',
  user_input = 'user_input',
  text_message = 'text_message',
  end_of_scenario = 'end_of_scenario',
}

export type TFacialExpression = 'SMILE';

export type TGesture = 'BOW';

export interface IMessageSequenceCall extends IMessage {
  instance_type: EInstanceType.sequence_call;
  params: IMessageSequenceCallParams;
}

export interface IMessageTextMessage extends IMessage {
  instance_type: EInstanceType.text_message;
  params: {
    display_text: string;
  };
}

export interface IMessageEndOfScenario extends IMessage {
  instance_type: EInstanceType.end_of_scenario;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}

export interface IMessageSequenceCallParams {
  sequence_id: string;
}

export interface IMessageAvatarUtterance extends IMessage {
  instance_type: EInstanceType.avatar_utterance;
  params: IMessageAvatarUtteranceParams;
}

export interface IMessageAvatarUtteranceParams {
  display_text: string;
  facial_expression: TFacialExpression;
  gesture?: string;
}

export interface IMessageAvatarMotion extends IMessage {
  instance_type: EInstanceType.avatar_motion;
  params: IMessageAvatarMotionParams;
}

export interface IMessageAvatarMotionParams {
  // FIXME: 意図せずgestureが入ってしまっている可能性が高いので、修正する
  gesture: string;
  motion_name: string;
  expression_name: string;
}

export interface IMessageAvatarMotionVideo extends IMessage {
  instance_type: EInstanceType.avatar_motion_video;
  params: IMessageAvatarMotionVideoParams;
}

export interface IMessageAvatarMotionVideoParams {
  motion_url: string;
}

export interface IMessageOpenImage extends IMessage {
  instance_type: EInstanceType.open_image;
  params: IMessageOpenImageParams;
}

export interface IMessageOpenImageParams {
  image_url: string;
}

export interface IMessageCloseImage extends IMessage {
  instance_type: EInstanceType.close_image;
  params: IMessageCloseImageParams;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMessageCloseImageParams {}

export interface IMessageOpenVideo extends IMessage {
  instance_type: EInstanceType.open_video;
  params: IMessageOpenVideoParams;
}

export interface IMessageOpenVideoParams {
  video_url: string;
  auto_close_if_play_end: boolean;
}

export interface IMessageCloseVideo extends IMessage {
  instance_type: EInstanceType.close_video;
  params: IMessageCloseVideoParams;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMessageCloseVideoParams {}

export interface IMessageSaveParam extends IMessage {
  instance_type: EInstanceType.save_param;
  params: IMessageSaveParamParams;
}

export interface IMessageSaveParamParams {
  param_name: string;
  param_value: string;
}

export interface IMessageRandomInstanceGroup extends IMessage {
  instance_type: EInstanceType.random_instance_group;
  params: IMessageRandomInstanceGroupParams;
}

export interface IMessageRandomInstanceGroupParams {
  instance_type: EInstanceType;
  instance_ids: string[];
}

export interface IMessageUserChoices extends IMessage {
  instance_type: EInstanceType.user_choices;
  params: IMessageUserChoicesParams;
}

export interface IMessageUserChoicesParams {
  choices: string[];
  save_name: string;
}

export interface IMessageParamBranch extends IMessage {
  instance_type: EInstanceType.param_branch;
  params: IMessageParamBranchParams;
}

export interface IMessageParamBranchParams {
  param_name: string;
  param_candidates: string;
}

export interface IMessageUserInput extends IMessage {
  instance_type: EInstanceType.user_input;
  params: IMessageUserInputParams;
}

export interface IMessageUserInputParams {
  input_type: string;
  save_name: string;
}

export interface IMessage {
  user_created: string;
  node_id: string;
  instance_type: EInstanceType;
  params: TMessageParams;
  created_at: string;
  updated_at: string;
}

export type TMessageParams =
  | IMessageSequenceCallParams
  | IMessageAvatarUtteranceParams
  | IMessageAvatarMotionParams
  | IMessageAvatarMotionVideoParams
  | IMessageOpenImageParams
  | IMessageCloseImageParams
  | IMessageOpenVideoParams
  | IMessageCloseVideoParams
  | IMessageSaveParamParams
  | IMessageRandomInstanceGroupParams
  | IMessageUserChoicesParams
  | IMessageParamBranchParams
  | IMessageUserInputParams
  | IMessageTextMessage['params']
  | IMessageEndOfScenario['params'];

export type TMessage =
  | IMessageSequenceCall
  | IMessageAvatarUtterance
  | IMessageAvatarMotion
  | IMessageAvatarMotionVideo
  | IMessageOpenImage
  | IMessageCloseImage
  | IMessageOpenVideo
  | IMessageCloseVideo
  | IMessageSaveParam
  | IMessageRandomInstanceGroup
  | IMessageUserChoices
  | IMessageParamBranch
  | IMessageUserInput
  | IMessageEndOfScenario
  | IMessageTextMessage;

export interface IChannelRepositoryStartParams {
  scenario_id: string;
}

export interface IChannelRepositoryResetParams {
  channel_id: string;
}

export interface IChannel {
  id: string;
  project_id: string;
  scenario_id: string;
  user_created: string;
}

export interface IChannelRepositoryStartResponse {
  data: {
    channel: IChannel;
    messages: TMessage[];
  };
}

export interface IChannelRepositoryResetResponse {
  data: {
    channel: IChannel;
    messages: TMessage[];
  };
}

export interface IChannelRepositoryBranchSelectionParams {
  node_id: string;
  selection: string;
  channel_id: string;
}

export interface IChannelRepositoryBranchSelectionResponse {
  data: {
    messages: TMessage[];
  };
}

export interface IChannelRepositoryInputResultParams {
  node_id: string;
  result: string;
  channel_id: string;
}

export interface IChannelRepositoryInputResultResponse {
  data: {
    messages: TMessage[];
  };
}
