import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { createChannel, resetRank } from '../../../../firebase';
import { useServices } from '../../../../service';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';

export const useRestartScenario = () => {
  const app = useRecoilValue(appState);
  const { identity } = useRecoilValue(chatbotIdentityState);
  const setIdentity = useSetRecoilState(chatbotIdentityState);
  const { scenarioService } = useServices();

  const { addMessage } = useMessageUtil();
  const reset = useCallback(async () => {
    try {
      const channelId = await createChannel(
        String(identity.projectId),
        identity.firebaseUuid,
        app.scenarioId,
        app.chatKey,
      );
      setIdentity((prev) => ({ ...prev, channelId }));
      resetRank();
      const response = await scenarioService.startScenario(
        {
          project_id: String(identity.projectId),
          scenario_id: app.scenarioId,
        },
        identity.firebaseToken,
        app.apiKey,
        app.siteOrigin,
      );
      await addMessage(response.scenario_data, identity.projectId, channelId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [
    addMessage,
    app.apiKey,
    app.chatKey,
    app.scenarioId,
    app.siteOrigin,
    identity.firebaseToken,
    identity.firebaseUuid,
    identity.projectId,
    scenarioService,
    setIdentity,
  ]);
  return { reset };
};
